import React from "react"
import { GatsbySeo } from 'gatsby-plugin-next-seo'

import Layout from "../components/layout"

const contactData = {
    title: "Contact Us",
    subHeading: "We would love to hear from you with any question or comments about our forthcoming phrasebooks. We will respond to your message promptly."
}

const termData = {
  title: "Submission Received",
  content: [
    {
      title: "Thanks!",
      content: [
        {
          text:
            "We have received your submission. We will be in touch shortly.",
        },
      ],
    },
  ],
}

const encode = (data) => {
  return Object.keys(data).map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])).join("&");
}

const initialState = {
  form: {
    name: "",
    email: "",
    message: "",
  },
  messageSent: false,
  messageSending: false,
}

class ContactPage extends React.Component {

  constructor(props) {
    super(props)
    this.state = initialState
  }

  handleSubmit = e => {
    this.setState({ messageSending: true })
    fetch("https://contactform-handler.langojango.workers.dev", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ ...this.state.form }),
    })
      .then(() => this.setState({ messageSent: true, messageSending: false }))
      .catch(() => this.setState({ messageSent: true, messageSending: false }))

    e.preventDefault()
  }

  handleChange = e => {
    let form = { ...this.state.form }
    form[e.target.name] = e.target.value
    this.setState({ form })
  }

  render() {
    if (!this.state.messageSent) {
      const { name, email, message } = this.state.form;
      return (
        <Layout>
          <GatsbySeo
            title="Contact Us | Langojango"
            description="Have a question, comment or concern about soon to be launched Langojango foreign language phrasebooks? Please send us a message."
            canonical="https://www.langojango.com/contact-us"
          />
          <div className="contactpage">
            <div className="container">
              <h1 className="heading-lg">{contactData.title}</h1>
              <p className="text-sm">{contactData.subHeading}</p>
              <form className="contact-us" onSubmit={this.handleSubmit}>
                <label>
                  Enter Your Name
                  <input
                    type="text"
                    name="name"
                    className="text-thin input-field"
                    placeholder="Name"
                    autoComplete="name"
                    onChange={this.handleChange}
                    value={name}
                    required
                  />
                </label>
                <label>
                  Enter Your Email
                  <input
                    type="email"
                    name="email"
                    value={email}
                    className="text-thin input-field"
                    placeholder="Email"
                    autoComplete="email"
                    onChange={this.handleChange}
                    required
                  />
                </label>
                <label>
                  Comments
                  <textarea
                    name="message"
                    value={message}
                    className="text-thin input-field"
                    placeholder="Comments"
                    onChange={this.handleChange}
                    required
                  />
                </label>
                <button
                  type="submit"
                  className={`${
                    this.state.messageSending ? "btn-gray" : "btn-green"
                  } text-sm`}
                  disabled={this.state.messageSending}
                >
                  {this.state.messageSending ? "Processing" : "Submit"}
                </button>
              </form>
            </div>
          </div>
        </Layout>
      )
    }
    else {
      return (
        <Layout>
          <GatsbySeo
            title="Submission Recieved | Langojango"
            description="We have received your submission."
            canonical="https://www.langojango.com/contact-us"
          />
          <div className="termpage">
            <div className="container">
              <h1 className="heading-lg">{termData.title}</h1>
              {termData.content.map((item, i) => {
                return (
                  <div className="term-wrapper" key={i}>
                    <h2 key={i} className="heading-sm">
                      {item.title}
                    </h2>
                    {item.content.map((itemTwo, j) => {
                      return (
                        <div key={j}>
                          <p className="text-sm">{itemTwo.text}</p>
                          {itemTwo.list ? (
                            <ul>
                              {itemTwo.list.map((itemThree, k) => {
                                return <li key={k}>{itemThree.listData}</li>
                              })}
                            </ul>
                          ) : (
                            ""
                          )}
                        </div>
                      )
                    })}
                  </div>
                )
              })}
            </div>
          </div>
        </Layout>
      )
    }
  }
}

export default ContactPage
